/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css');
@import 'node_modules/angular-notifier/styles/core';
@import 'node_modules/angular-notifier/styles/themes/theme-material';
@import "node_modules/angular-notifier/styles/types/type-default";
@import "node_modules/angular-notifier/styles/types/type-info";
@import "node_modules/angular-notifier/styles/types/type-error";
@import "node_modules/angular-notifier/styles/types/type-success";

body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  overscroll-behavior: none;
}
body {
  overscroll-behavior: none;
}

* {
  box-sizing: border-box;
}
